import {OrganizationRef} from "../../../model";
import {ActorCard} from "../ActorCard";
import {CertificateSection} from "../../../template/Certificate/Section";

export interface ActorTransformationCardProps {
  title: string,
  items: OrganizationRef[]
}

export const ActorTransformationCard = (props: ActorTransformationCardProps) => {
  const {title, items} = props;
  return (
    <CertificateSection withBackground title={title}>
      {items.map((organization, index) => (
        <ActorCard item={organization} key={index}/>
      ))}
    </CertificateSection>
  );
}
