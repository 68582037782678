import {useParams} from "react-router";
import {RealizationCertificateSection} from "../../components/realization/RealizationCertificateSection";
import {AppLoading} from "@tracabois/components";
import {useRealizationCertificateGetQuery} from "../../api";

export interface RealizationProps {
}

export const RealizationPage = (_: RealizationProps) => {
  const {realizationId = ""} = useParams<{ realizationId: string }>();
  const realizationResult = useRealizationCertificateGetQuery({ id: realizationId })
  if(realizationResult.isLoading) {
    return (<AppLoading />)
  }
  return (
   <RealizationCertificateSection item={realizationResult.data!!} />
  );
}
