import {useParams} from "react-router";
import {OuvrageCertificateSection} from "../../components/ouvrage/OuvrageCertificateSection";
import {AppLoading} from "@tracabois/components";
import {useOuvrageCertificateGetQuery} from "../../api";

export interface HomeProps {
}

export const OuvragePage = (_: HomeProps) => {
  const {ouvrageId = ""} = useParams<{ ouvrageId: string }>();
  const result = useOuvrageCertificateGetQuery({ id: ouvrageId })

  if(result.isLoading) {
    return (<AppLoading />)
  }
  return (
    <OuvrageCertificateSection item={result.data!!} />
  );
}
