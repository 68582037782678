import {OuvrageCertificateRef, RealizationCertificate} from "../../model";
import {Grid} from "@mui/material";
import {goto} from "../../../router/certificate.goto";
import {CertificateSection} from "../../template/Certificate/Section";
import {useNavigate} from "react-router";
import {OuvrageRefSection} from "./OuvrageRefSection";

export interface OuvrageRefsCardProps {
  item: RealizationCertificate
}

export const OuvrageRefsSection = (props: OuvrageRefsCardProps) => {
  const {item} = props;
  const navigate = useNavigate()
  return (
    <CertificateSection title="Ouvrage">
      <Grid container spacing={2}>
      {
        item.ouvrages.map((ouvrage: OuvrageCertificateRef, index: number) => (
          <Grid item xs key={`Grid-${index}`}>
              <OuvrageRefSection
                item={ouvrage}
                onClick={it => goto.viewOuvrage(it.id, navigate)}
              />
          </Grid>
        ))
      }
      </Grid>
    </CertificateSection>
  );
}
