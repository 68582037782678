import {OrganizationRef} from "../../../model";
import {Avatar, Stack, Typography} from "@mui/material";
import {OrgRolesArray} from "@tracabois/connect";
import {useCallback} from "react";
import {getOrganizationLogoUrl} from "@tracabois/domain";

export interface ActorCardProps {
  item: OrganizationRef
}

export const ActorCard = (props: ActorCardProps) => {
  const {item} = props;
  // @ts-ignore
  const img =  getOrganizationLogoUrl(item.id)
  const goToRedirectUrl = useCallback(() => {
    if(item?.website) {
      const website = item.website.startsWith("http") ? item.website : `http://${item.website}`
      item?.website && window.open(website, '_blank');
    }
  }, [item.website])
  const cursor = item?.website ? "pointer" : "default"
  return (
    <Stack direction="row" spacing={2} marginBottom={1} onClick={goToRedirectUrl} sx={{cursor: cursor}}>
      <Stack sx={{ minWidth: 100, width: 100, height: 100 }} ><Avatar variant="rounded" sx={{
        width: "100%",
        height: "100%",
        "& .MuiAvatar-img": {
            width: "100%",
            height: "auto"
          }
      }} src={img} />
      </Stack>
      <Stack direction="column">
        <Stack>
          <Typography variant="h6" sx={{ color: "secondary.main" }}>{item.name}</Typography>
        </Stack>
        <Stack>
          <Typography variant="body2" sx={{ color: "primary.main" }}>{item.roles.filter(it => OrgRolesArray.includes(it)).join(", ")}</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}
