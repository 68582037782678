import {OuvrageCertificateRef} from "../../model";
import {Avatar, Stack, Typography} from "@mui/material";
import {useGetGallery} from "@smartb/g2-fs";
import {fsConfig} from "@smartb/g2-providers";
import defaultImg from "./asset/img.png"

export interface OuvrageRefCardProps {
  item: OuvrageCertificateRef
  onClick: (ref: OuvrageCertificateRef) => void
}

export const OuvrageRefSection = (props: OuvrageRefCardProps) => {
  const {item, onClick} = props;
  const gallery = useGetGallery({
    apiUrl: fsConfig().url,
    directoryPath: {
      directory: 'gallery',
      objectType: 'ouvrage',
      objectId: item.id,
    },
    queryKey: item.id
  })
  const img = gallery.data?.items.length !== 0 ? gallery.data?.items[0]?.url : defaultImg
  return (
    <Stack direction="column" onClick={() => onClick(item)} sx={{cursor: "pointer"}}>
      <Stack>
        <Avatar src={img} variant="rounded" sx={{ width: 200, height: 200, borderRadius: 1 }}/>
      </Stack>
      <Stack>
        <Typography variant="h6" color="text.secondary">{item.name}</Typography>
      </Stack>
    </Stack>
  );
}
