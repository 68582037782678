import {AutomatedGallery} from "@smartb/g2-fs"
import {OuvrageIndicatorGrid, RemovalZoneCardStack, WoodSpeciesCard} from "@tracabois/domain"
import {CertificateHeader} from "../../template/Certificate/Header";
import {CertificateColumn} from "../../template/Certificate/Column";
import {CertificateBody} from "../../template/Certificate/Body";
import {CertificatePage} from "../../template/Certificate/Page";
import {AppLoading} from "@tracabois/components";
import {useMediaQuery} from "@mui/material";
import {CertificateSection} from "../../template/Certificate/Section";
import {MapGeoJson} from "@tracabois/components/src/Map";
import {OuvrageCertificate} from "../../model";
import { Feature, LineString, MultiLineString, Point, Position } from "geojson";
import { LatLngBounds, LatLngLiteral } from "leaflet";
import L from "leaflet";

export interface OuvrageCertificateProps {
  item: OuvrageCertificate
}

export const OuvrageCertificateSection = (props: OuvrageCertificateProps) => {
  const {item} = props
  const isMobile = useMediaQuery('(max-width:800px)')

  const getBounds = (): LatLngBounds => {
    let coordinates: LatLngLiteral[] = []
  
    item.geoJson.features.forEach((feature: Feature) => {
      switch (feature.geometry.type) {
        case "Point":
          coordinates.push({
            lng: (feature.geometry as Point).coordinates[0],
            lat: (feature.geometry as Point).coordinates[1]
          })
          break;
        case "LineString" || "MultiPoint":
          (feature.geometry as LineString).coordinates.forEach((position: Position) => {
            coordinates.push({
              lng: position[0],
              lat: position[1]
            })
          })
          break
          case "MultiLineString" || "Polygon":
            (feature.geometry as MultiLineString).coordinates.forEach((positions: Position[]) => {
              positions.forEach((position: Position) => {
                coordinates.push({
                  lng: position[0],
                  lat: position[1]
                })
              })
            })
            break
        default:
          break
      }
    })

    const lngsDescending = coordinates.map(coord => coord.lng).sort((a, b) => b - a)
    const latsDescending = coordinates.map(coord => coord.lat).sort((a, b) => b - a)

    const southWestBound = L.latLng(latsDescending[latsDescending.length - 1], lngsDescending[lngsDescending.length - 1])
    const northEastBound = L.latLng(latsDescending[0], lngsDescending[0])

    return L.latLngBounds(southWestBound, northEastBound)
  }

  const bounds = getBounds()

  if (!item) {
    return <AppLoading/>
  }
  const galerie = (<AutomatedGallery
    getGalleryQueryKey={item.id}
    directoryPath={{
      directory: 'gallery',
      objectType: 'ouvrage',
      objectId: item.id,
    }}
    galleryName={`La galerie d'image de ${item.name}`}
    variant="grid"
    gridProps={{
      cols: 1,
      variant: "quilted"
    }}
  />)

  return (
    <CertificatePage>
      <CertificateHeader title={item.realization?.name || ""} productType={item.name}/>
      {isMobile && galerie}
      <CertificateBody variant={isMobile ?  "column" : "row"}>
        <CertificateColumn>
          <OuvrageIndicatorGrid indicators={item.indicators}/>
          <CertificateSection title="Essences utilisées">
            <WoodSpeciesCard items={item.species}/>
          </CertificateSection>
        </CertificateColumn>
        <CertificateColumn>
          <MapGeoJson geoJson={item.geoJson} bounds={bounds}/>
          <CertificateSection title="Origine">
            <RemovalZoneCardStack items={item.removalZones}/>
          </CertificateSection>
        </CertificateColumn>
        <CertificateColumn>
          {!isMobile && galerie}
        </CertificateColumn>
      </CertificateBody>
    </CertificatePage>
  );
}
