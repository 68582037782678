import {Stack} from "@mui/material";
import React from "react";

export interface CertificateColumnProps {
  children?: React.ReactNode;
}

export const CertificateColumn = (props: CertificateColumnProps) => {
  const {children} = props
  return (
    <Stack
      sx={{
        flex: 1,
        maxWidth: "500px"
      }}
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      {children}
    </Stack>
  )
}