import {Stack, Typography, useMediaQuery} from "@mui/material";
import {ReactElement} from "react";

export interface CertificateSectionProps {
  title: string
  withBackground?: boolean
  children: ReactElement | ReactElement[]
}

export const CertificateSection = (props: CertificateSectionProps) => {
  const {title, children, withBackground = false} = props
  const isMobile = useMediaQuery('(max-width:800px)')
  const sx = withBackground ? {backgroundColor: "#57454A10"} : {}
  return (
    <Stack direction="column" marginBottom={2}  width={"100%"} sx={sx}>
      <Stack marginTop={2} marginLeft={2}>
       <Typography sx={{color: "secondary.main"}}
                  variant={isMobile ? "subtitle1" : "subtitle1"}>{title}</Typography>
      </Stack>
      <Stack margin={2}>
        {children}
      </Stack>
    </Stack>
  )
}
