import {Stack, Typography, useMediaQuery} from "@mui/material";
import {LogoWithTitle} from "@tracabois/components";

export interface CertificateHeaderProps {
  title: string
  productType?: string
}

export const CertificateHeader = (props: CertificateHeaderProps) => {
  const {title, productType} = props
  const isMobile = useMediaQuery('(max-width:800px)')
  return (<>
    <Typography variant={isMobile ? 'h6' : 'h3'} sx={{color: "secondary.main"}}>
      {title}
    </Typography>
    <Stack
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      sx={{
        gap: "15px",
        margin: '20px 0'
      }}
    >
      <LogoWithTitle sx={{maxWidth: "500px"}} isMobile={isMobile}/>
      {productType &&
      <Stack
          justifyContent="center"
          alignItems="flex-end"
          flex={1}
      >
          <Typography sx={{color: "primary.main"}} variant={isMobile ? "subtitle1" : "h6"}>Ouvrage</Typography>
          <Typography sx={{color: "secondary.main", textTransform: "uppercase"}}
                      variant={isMobile ? "h6" : "h5"}>{productType}</Typography>
      </Stack>
      }
    </Stack>
  </>)
}
