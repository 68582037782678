import {Box} from "@mui/material";
import React from "react";

export interface CertificatePageProps {
  children?: React.ReactNode;
}

export const CertificatePage = (props: CertificatePageProps) => {
  const {children} = props
  return (
    <Box
      sx={{
        padding: "20px",
        paddingTop: "30px",
        backgroundColor: "white"
      }}
    >
      {children}
    </Box>
  )
}