import React from "react";
import {
  AppProvider,
  G2ConfigBuilder 
} from "@smartb/g2-providers";
import { ThemeContextProvider } from "@smartb/g2-themes";
import App from "App";
import { languages, theme } from "@tracabois/domain";
import { QueryClient} from 'react-query'
import {store} from "./store";
import {AppLoading} from "@tracabois/components";
import {createRoot} from "react-dom/client";
import {Provider} from "react-redux";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 86400000
    }
  }
})

// @ts-ignore
G2ConfigBuilder(window._env_.config)
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ThemeContextProvider theme={theme}>
      <Provider store={store}>
          <AppProvider
            languages={languages}
            loadingComponent={<AppLoading />}
            queryClient={queryClient}
          >
              <App />
          </AppProvider>
      </Provider>
    </ThemeContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
