import {Stack} from "@mui/material";
import React from "react";

export type CertificateBodyVariant = "row" | "column"

export interface CertificateBodyProps {
  children?: React.ReactNode;
  variant?: CertificateBodyVariant
}

export const CertificateBody = (props: CertificateBodyProps) => {
  const {children, variant = "row"} = props
  return (
    <Stack
      direction={variant}
      flexWrap="wrap"
      justifyContent="space-evenly"
      sx={{
        gap: "15px"
      }}
    >
      {children}
    </Stack>
  )
}