import {
  RealizationIndicatorGrid,
  RemovalZoneCardStack,
  WoodSpeciesCard
} from "@tracabois/domain"
import {AutomatedGallery} from "@smartb/g2-fs"
import {CertificateHeader} from "../../template/Certificate/Header";
import {CertificateColumn} from "../../template/Certificate/Column";
import {CertificateBody} from "../../template/Certificate/Body";
import {CertificatePage} from "../../template/Certificate/Page";
import {RealizationCertificate} from "../../model";
import {CertificateSection} from "../../template/Certificate/Section";
import {ActorTransformationCard} from "../Actor/ActorTransformationCard";
import {OuvrageRefsSection} from "./OuvrageRefsSection";
import {useMediaQuery} from "@mui/material";

export interface RealizationCertificateProps {
  item: RealizationCertificate
}

export const RealizationCertificateSection = (props: RealizationCertificateProps) => {
  const {item} = props;
  const isMobile = useMediaQuery('(max-width:800px)')
  const galery = (
    <AutomatedGallery
      getGalleryQueryKey={item.id}
      directoryPath={{
        directory: 'gallery',
        objectType: 'realization',
        objectId: item.id,
      }}
      getGalleryOptions={{
        queryHash: item.id
      }}
      galleryName='la galerie de traçabois'
      variant="grid"
      gridProps={{
        cols: 1,
        variant: "quilted"
      }}
  />)
  return (
    <CertificatePage>
      <CertificateHeader title={item.name}/>
      {isMobile && galery}
      <CertificateBody>
        <CertificateColumn>
          <RealizationIndicatorGrid indicators={item.indicators}/>
          <CertificateSection title="Essences utilisées">
            <WoodSpeciesCard items={item.species} alignItems={"center"}/>
          </CertificateSection>
        </CertificateColumn>
        <CertificateColumn>
          <ActorTransformationCard title={"Mise en oeuvre"} items={item.moes}/>
          <ActorTransformationCard title={"Réalisation"} items={item.actors}/>
        </CertificateColumn>
        <CertificateColumn>
          <OuvrageRefsSection item={item}/>
          <CertificateSection title="Origine">
            <RemovalZoneCardStack items={item.removalZones}/>
          </CertificateSection>
        </CertificateColumn>
        {!isMobile && (
        <CertificateColumn>
          {galery}
        </CertificateColumn>
        )}
      </CertificateBody>
    </CertificatePage>
  );
}
